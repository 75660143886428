@font-face {
  font-family: SuisseIntl-Regular;
  font-weight: 400;
  src: url("./styles/fonts/SuisseIntl-Regular.otf") format("opentype");
}
@font-face {
  font-family: SuisseIntl-Medium;
  font-weight: 500;
  src: url("./styles/fonts/SuisseIntl-Medium.otf") format("opentype");
}
@font-face {
  font-family: SuisseIntl-Bold;
  font-weight: 700;
  src: url("./styles/fonts/SuisseIntl-Bold.otf") format("opentype");
}
:root {
  --white-darken-5: #f6f4ef;
  --hint-darken: #352c25;
  --send-darken-20: #5b5855;
  --send: #8a867b;
  --send-light: #afaca3;
  --hint-light: #d1cfc9;
  --white: #ffffff;
  --orange-links: #ffa000;
  --orange-links-light-5: #ffb803;
  --orange-links-darken-5: #da9013;
  --black: #000000;
  --font-default: SuisseIntl-Regular;
  --font-bold: SuisseIntl-Bold;
  --font-medium: SuisseIntl-Medium;
  --transition: all 0.3s;
}

@font-face {
  font-family: SuisseIntl-Regular;
  font-weight: 400;
  src: url("./styles/fonts/SuisseIntl-Regular.otf") format("opentype");
}
@font-face {
  font-family: SuisseIntl-Medium;
  font-weight: 500;
  src: url("./styles/fonts/SuisseIntl-Medium.otf") format("opentype");
}
@font-face {
  font-family: SuisseIntl-Bold;
  font-weight: 700;
  src: url("./styles/fonts/SuisseIntl-Bold.otf") format("opentype");
}
:root {
  --white-darken-5: #f6f4ef;
  --hint-darken: #352c25;
  --send-darken-20: #5b5855;
  --send: #8a867b;
  --send-light: #afaca3;
  --hint-light: #d1cfc9;
  --white: #ffffff;
  --orange-links: #ffa000;
  --orange-links-light-5: #ffb803;
  --orange-links-darken-5: #da9013;
  --black: #000000;
  --font-default: SuisseIntl-Regular;
  --font-bold: SuisseIntl-Bold;
  --font-medium: SuisseIntl-Medium;
  --transition: all 0.3s;
}

@font-face {
  font-family: SuisseIntl-Regular;
  font-weight: 400;
  src: url("./styles/fonts/SuisseIntl-Regular.otf") format("opentype");
}
@font-face {
  font-family: SuisseIntl-Medium;
  font-weight: 500;
  src: url("./styles/fonts/SuisseIntl-Medium.otf") format("opentype");
}
@font-face {
  font-family: SuisseIntl-Bold;
  font-weight: 700;
  src: url("./styles/fonts/SuisseIntl-Bold.otf") format("opentype");
}
:root {
  --white-darken-5: #f6f4ef;
  --hint-darken: #352c25;
  --send-darken-20: #5b5855;
  --send: #8a867b;
  --send-light: #afaca3;
  --hint-light: #d1cfc9;
  --white: #ffffff;
  --orange-links: #ffa000;
  --orange-links-light-5: #ffb803;
  --orange-links-darken-5: #da9013;
  --black: #000000;
  --font-default: SuisseIntl-Regular;
  --font-bold: SuisseIntl-Bold;
  --font-medium: SuisseIntl-Medium;
  --transition: all 0.3s;
}

.App {
  width: calc(100vw - (100vw - 100%));
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 142px;
  overflow-x: hidden;
  background-color: var(--white-darken-5);
}
@media (max-width: 1199px) {
  .App {
    grid-template-rows: auto auto;
  }
}
@media (max-width: 767px) {
  .App {
    grid-template-rows: auto;
  }
}
@media (max-width: 575px) {
  .App {
    background-color: var(--white);
  }
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
  font-family: var(--font-default);
}

html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}

a {
  color: var(--send);
  font-size: 14px;
  text-decoration: none;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  cursor: pointer;
}
a:hover {
  color: var(--orange-links);
}
a:hover svg path {
  fill: var(--orange-links);
}

@font-face {
  font-family: SuisseIntl-Regular;
  font-weight: 400;
  src: url("./styles/fonts/SuisseIntl-Regular.otf") format("opentype");
}
@font-face {
  font-family: SuisseIntl-Medium;
  font-weight: 500;
  src: url("./styles/fonts/SuisseIntl-Medium.otf") format("opentype");
}
@font-face {
  font-family: SuisseIntl-Bold;
  font-weight: 700;
  src: url("./styles/fonts/SuisseIntl-Bold.otf") format("opentype");
}
:root {
  --white-darken-5: #f6f4ef;
  --hint-darken: #352c25;
  --send-darken-20: #5b5855;
  --send: #8a867b;
  --send-light: #afaca3;
  --hint-light: #d1cfc9;
  --white: #ffffff;
  --orange-links: #ffa000;
  --orange-links-light-5: #ffb803;
  --orange-links-darken-5: #da9013;
  --black: #000000;
  --font-default: SuisseIntl-Regular;
  --font-bold: SuisseIntl-Bold;
  --font-medium: SuisseIntl-Medium;
  --transition: all 0.3s;
}

.header {
  background-color: var(--white);
  -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.031372549);
          box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.031372549);
  display: none;
}
@media (max-width: 767px) {
  .header {
    display: none;
  }
}

@font-face {
  font-family: SuisseIntl-Regular;
  font-weight: 400;
  src: url("./styles/fonts/SuisseIntl-Regular.otf") format("opentype");
}
@font-face {
  font-family: SuisseIntl-Medium;
  font-weight: 500;
  src: url("./styles/fonts/SuisseIntl-Medium.otf") format("opentype");
}
@font-face {
  font-family: SuisseIntl-Bold;
  font-weight: 700;
  src: url("./styles/fonts/SuisseIntl-Bold.otf") format("opentype");
}
:root {
  --white-darken-5: #f6f4ef;
  --hint-darken: #352c25;
  --send-darken-20: #5b5855;
  --send: #8a867b;
  --send-light: #afaca3;
  --hint-light: #d1cfc9;
  --white: #ffffff;
  --orange-links: #ffa000;
  --orange-links-light-5: #ffb803;
  --orange-links-darken-5: #da9013;
  --black: #000000;
  --font-default: SuisseIntl-Regular;
  --font-bold: SuisseIntl-Bold;
  --font-medium: SuisseIntl-Medium;
  --transition: all 0.3s;
}

.main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 575px) {
  .main {
    position: relative;
    height: 100dvh;
  }
}
.main-image {
  max-height: 370px;
}
@media (max-width: 575px) {
  .main-image {
    display: none;
  }
}

@font-face {
  font-family: SuisseIntl-Regular;
  font-weight: 400;
  src: url("./styles/fonts/SuisseIntl-Regular.otf") format("opentype");
}
@font-face {
  font-family: SuisseIntl-Medium;
  font-weight: 500;
  src: url("./styles/fonts/SuisseIntl-Medium.otf") format("opentype");
}
@font-face {
  font-family: SuisseIntl-Bold;
  font-weight: 700;
  src: url("./styles/fonts/SuisseIntl-Bold.otf") format("opentype");
}
:root {
  --white-darken-5: #f6f4ef;
  --hint-darken: #352c25;
  --send-darken-20: #5b5855;
  --send: #8a867b;
  --send-light: #afaca3;
  --hint-light: #d1cfc9;
  --white: #ffffff;
  --orange-links: #ffa000;
  --orange-links-light-5: #ffb803;
  --orange-links-darken-5: #da9013;
  --black: #000000;
  --font-default: SuisseIntl-Regular;
  --font-bold: SuisseIntl-Bold;
  --font-medium: SuisseIntl-Medium;
  --transition: all 0.3s;
}

.radio-options {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 12px;
  padding: 12px;
}
@media (max-width: 575px) {
  .radio-options {
    background-color: var(--white-darken-5);
    padding: 16px 12px;
    gap: 8px;
    border-radius: 12px;
  }
}

@font-face {
  font-family: SuisseIntl-Regular;
  font-weight: 400;
  src: url("./styles/fonts/SuisseIntl-Regular.otf") format("opentype");
}
@font-face {
  font-family: SuisseIntl-Medium;
  font-weight: 500;
  src: url("./styles/fonts/SuisseIntl-Medium.otf") format("opentype");
}
@font-face {
  font-family: SuisseIntl-Bold;
  font-weight: 700;
  src: url("./styles/fonts/SuisseIntl-Bold.otf") format("opentype");
}
:root {
  --white-darken-5: #f6f4ef;
  --hint-darken: #352c25;
  --send-darken-20: #5b5855;
  --send: #8a867b;
  --send-light: #afaca3;
  --hint-light: #d1cfc9;
  --white: #ffffff;
  --orange-links: #ffa000;
  --orange-links-light-5: #ffb803;
  --orange-links-darken-5: #da9013;
  --black: #000000;
  --font-default: SuisseIntl-Regular;
  --font-bold: SuisseIntl-Bold;
  --font-medium: SuisseIntl-Medium;
  --transition: all 0.3s;
}

.radio-option {
  display: block;
  position: relative;
  width: 100%;
  border-radius: 12px;
  padding: 19px 12px;
  background-color: var(--white);
  cursor: pointer;
  /* Create a custom radio button */
}
.radio-option .container {
  position: relative;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  color: var(--send-darken-20);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  /* Hide the browser's default radio button */
}
@media (max-width: 575px) {
  .radio-option .container {
    font-size: 16px;
    font-weight: 500;
    color: var(--hint-darken);
  }
}
.radio-option .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  /* When the radio button is checked, change border color */
}
.radio-option .container input:checked ~ .checkmark {
  background-color: var(--orange-links-light-5);
  border: 1px solid var(--orange-links-darken-5);
  outline: 3px solid var(--white);
  outline-offset: -4px;
}
.radio-option .checkmark {
  position: absolute;
  top: 0;
  right: 4px;
  width: 16px;
  height: 16px;
  background-color: var(--white);
  border: 1px solid var(--send);
  border-radius: 50%;
}
@media (max-width: 575px) {
  .radio-option .checkmark {
    width: 18px;
    height: 18px;
  }
}

@font-face {
  font-family: SuisseIntl-Regular;
  font-weight: 400;
  src: url("./styles/fonts/SuisseIntl-Regular.otf") format("opentype");
}
@font-face {
  font-family: SuisseIntl-Medium;
  font-weight: 500;
  src: url("./styles/fonts/SuisseIntl-Medium.otf") format("opentype");
}
@font-face {
  font-family: SuisseIntl-Bold;
  font-weight: 700;
  src: url("./styles/fonts/SuisseIntl-Bold.otf") format("opentype");
}
:root {
  --white-darken-5: #f6f4ef;
  --hint-darken: #352c25;
  --send-darken-20: #5b5855;
  --send: #8a867b;
  --send-light: #afaca3;
  --hint-light: #d1cfc9;
  --white: #ffffff;
  --orange-links: #ffa000;
  --orange-links-light-5: #ffb803;
  --orange-links-darken-5: #da9013;
  --black: #000000;
  --font-default: SuisseIntl-Regular;
  --font-bold: SuisseIntl-Bold;
  --font-medium: SuisseIntl-Medium;
  --transition: all 0.3s;
}

.button {
  all: unset;
  width: 255px;
  padding: 17px 32px 17px 32px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-align: center !important;
  letter-spacing: -0.5%;
  background-color: var(--hint-light);
  color: var(--white);
  border-radius: 10px;
  cursor: not-allowed;
}
.button.active {
  background-color: var(--orange-links);
  cursor: pointer;
}

@font-face {
  font-family: SuisseIntl-Regular;
  font-weight: 400;
  src: url("./styles/fonts/SuisseIntl-Regular.otf") format("opentype");
}
@font-face {
  font-family: SuisseIntl-Medium;
  font-weight: 500;
  src: url("./styles/fonts/SuisseIntl-Medium.otf") format("opentype");
}
@font-face {
  font-family: SuisseIntl-Bold;
  font-weight: 700;
  src: url("./styles/fonts/SuisseIntl-Bold.otf") format("opentype");
}
:root {
  --white-darken-5: #f6f4ef;
  --hint-darken: #352c25;
  --send-darken-20: #5b5855;
  --send: #8a867b;
  --send-light: #afaca3;
  --hint-light: #d1cfc9;
  --white: #ffffff;
  --orange-links: #ffa000;
  --orange-links-light-5: #ffb803;
  --orange-links-darken-5: #da9013;
  --black: #000000;
  --font-default: SuisseIntl-Regular;
  --font-bold: SuisseIntl-Bold;
  --font-medium: SuisseIntl-Medium;
  --transition: all 0.3s;
}

.branch-picker {
  width: 353px;
  padding-top: 88px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 24px;
  font-family: var(--font-default);
}
@media (max-width: 575px) {
  .branch-picker {
    width: 100%;
    padding: 12px 16px;
    gap: 17px;
  }
}
.branch-picker * {
  font-weight: 400;
  letter-spacing: -0.5%;
  text-align: center;
}
@media (max-width: 575px) {
  .branch-picker * {
    text-align: left;
  }
}
.branch-picker h1 {
  width: 100%;
  font-size: 20px;
  line-height: 1;
  color: var(--hint-darken);
}
@media (max-width: 575px) {
  .branch-picker h1 {
    color: var(--send-darken-20);
  }
}
.branch-picker p {
  width: 100%;
  font-size: 14px;
  line-height: 18.2px;
  color: var(--send);
}
@media (max-width: 575px) {
  .branch-picker p {
    color: var(--send-darken-20);
  }
}
@media (max-width: 575px) {
  .branch-picker--button {
    position: absolute;
    bottom: 30px;
    max-width: calc(100% - 32px);
  }
}

@font-face {
  font-family: SuisseIntl-Regular;
  font-weight: 400;
  src: url("./styles/fonts/SuisseIntl-Regular.otf") format("opentype");
}
@font-face {
  font-family: SuisseIntl-Medium;
  font-weight: 500;
  src: url("./styles/fonts/SuisseIntl-Medium.otf") format("opentype");
}
@font-face {
  font-family: SuisseIntl-Bold;
  font-weight: 700;
  src: url("./styles/fonts/SuisseIntl-Bold.otf") format("opentype");
}
:root {
  --white-darken-5: #f6f4ef;
  --hint-darken: #352c25;
  --send-darken-20: #5b5855;
  --send: #8a867b;
  --send-light: #afaca3;
  --hint-light: #d1cfc9;
  --white: #ffffff;
  --orange-links: #ffa000;
  --orange-links-light-5: #ffb803;
  --orange-links-darken-5: #da9013;
  --black: #000000;
  --font-default: SuisseIntl-Regular;
  --font-bold: SuisseIntl-Bold;
  --font-medium: SuisseIntl-Medium;
  --transition: all 0.3s;
}

.footer {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: minmax(55px, auto) 1px minmax(55px, auto);
  gap: 0 20px;
  grid-auto-flow: row;
  grid-template-areas: "social-link social-link . design-author design-author" "divider divider divider divider divider" "officces officces copyright contacts contacts";
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 24px;
  font-family: var(--font-default);
  color: var(--send);
}
.footer .underline {
  text-decoration: underline !important;
}
.footer a,
.footer .link {
  color: var(--send);
  text-decoration: none;
  font-size: 12px;
  cursor: pointer;
}
.footer a span,
.footer .link span {
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
}
.footer a:hover,
.footer .link:hover {
  color: var(--orange-links);
}
@media (max-width: 1199px) {
  .footer {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, auto);
    grid-template-areas: "social-link" "contact-phone" "divider" "officces" "contacts" "design-author";
    text-align: center;
    padding-top: 40px;
    padding-bottom: 24px;
  }
}
@media (max-width: 767px) {
  .footer {
    display: none;
  }
}
.footer__divider {
  grid-area: divider;
  display: block;
  height: 1px;
  width: 100%;
  background-color: var(--hint-light);
}
@media (max-width: 1199px) {
  .footer__divider {
    margin: 32px 0;
  }
}
.footer__social-link {
  grid-area: social-link;
}
.footer__social-link ul {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.footer__social-link li {
  list-style: none;
}
.footer__social-link li:not(:first-child) {
  margin-left: 10px;
}
.footer__social-link li:not(:last-child) {
  margin-right: 10px;
}
.footer__social-link a {
  display: block;
}
.footer__social-link a:hover svg path {
  fill: var(--orange-links);
}
.footer__social-link svg path {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
.footer__design-author {
  grid-area: design-author;
  font-family: var(--font-default);
  justify-self: end;
}
.footer__design-author a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 18px;
}
.footer__design-author a:hover {
  color: var(--orange-links);
}
.footer__design-author a:hover svg path:first-child {
  fill: var(--orange-links);
}
.footer__design-author svg {
  margin-left: 8px;
}
.footer__design-author svg path {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
@media (max-width: 1199px) {
  .footer__design-author {
    margin-top: 32px;
    justify-self: center;
  }
}
.footer__contacts {
  grid-area: contacts;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
@media (max-width: 1199px) {
  .footer__contacts {
    margin-top: 32px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.footer__contacts-phone {
  grid-area: contact-phone;
}
.footer__contacts-phone ul {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.footer__contacts-phone ul li:not(:first-child) {
  margin-left: 10px;
}
.footer__contacts-phone ul li:not(:last-child) {
  margin-right: 10px;
}
@media (max-width: 1199px) {
  .footer__contacts-phone {
    margin-top: 32px;
  }
}
.footer__officces {
  grid-area: officces;
}
.footer__officces ul {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.footer__officces ul li {
  list-style: none;
}
.footer__officces ul li:not(:first-child) {
  margin-left: 10px;
}
.footer__officces ul li:not(:last-child) {
  margin-right: 22px;
}
.footer__copyright {
  font-size: 12px;
  text-align: center;
}
.footer__privacy-policy {
  margin-right: 32px;
}

@font-face {
  font-family: SuisseIntl-Regular;
  font-weight: 400;
  src: url("./styles/fonts/SuisseIntl-Regular.otf") format("opentype");
}
@font-face {
  font-family: SuisseIntl-Medium;
  font-weight: 500;
  src: url("./styles/fonts/SuisseIntl-Medium.otf") format("opentype");
}
@font-face {
  font-family: SuisseIntl-Bold;
  font-weight: 700;
  src: url("./styles/fonts/SuisseIntl-Bold.otf") format("opentype");
}
:root {
  --white-darken-5: #f6f4ef;
  --hint-darken: #352c25;
  --send-darken-20: #5b5855;
  --send: #8a867b;
  --send-light: #afaca3;
  --hint-light: #d1cfc9;
  --white: #ffffff;
  --orange-links: #ffa000;
  --orange-links-light-5: #ffb803;
  --orange-links-darken-5: #da9013;
  --black: #000000;
  --font-default: SuisseIntl-Regular;
  --font-bold: SuisseIntl-Bold;
  --font-medium: SuisseIntl-Medium;
  --transition: all 0.3s;
}

.modal {
  background-color: var(--white);
  width: 500px;
  padding: 24px;
}
.modal ::-webkit-scrollbar {
  width: 4px;
}
.modal ::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
  cursor: pointer;
}
.modal ::-webkit-scrollbar-thumb {
  background: var(--send);
  border-radius: 10px;
  cursor: pointer;
}
@media (max-width: 767px) {
  .modal {
    width: 320px;
  }
}
.modal__head {
  position: relative;
}
.modal__close {
  -webkit-transform: scale(2);
      -ms-transform: scale(2);
          transform: scale(2);
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}
.modal__close:hover path {
  stroke: var(--black);
}
.modal__close path {
  stroke: var(--send-light);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
.modal__title {
  padding-top: 24px;
  font-family: var(--font-medium);
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
}
.modal__body {
  height: calc(100vh - 400px);
  padding-top: 16px;
  padding-right: 10px;
}
.modal__body-inner {
  overflow-y: scroll;
  max-height: 100%;
}
.modal__body__inline-icon {
  display: inline-block;
  position: relative;
  top: 5px;
  width: 24px;
  height: 24px;
}
.modal__body p {
  color: var(--black);
  font-family: var(--font-default);
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}
.modal__buttons {
  padding-top: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 767px) {
  .modal__buttons {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.modal__buttons .button {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.modal__buttons .button:only-child {
  width: 100%;
}
.modal__buttons .button:nth-child(2) {
  margin-left: 24px;
}
@media (max-width: 767px) {
  .modal__buttons .button {
    width: 100%;
    padding: 16px 5px;
  }
  .modal__buttons .button:nth-child(2) {
    margin-top: 24px;
    margin-left: 0;
  }
}

.popup-overlay {
  background-color: rgba(27, 26, 25, 0.5);
}

.popup-content {
  -webkit-animation: modal 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

@-webkit-keyframes modal {
  0% {
    -webkit-transform: scale(1) translateY(0px);
            transform: scale(1) translateY(0px);
    opacity: 0;
    -webkit-box-shadow: 0 0 0 rgba(241, 241, 241, 0);
            box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    -webkit-transform: scale(0.8) translateY(10px);
            transform: scale(0.8) translateY(10px);
    opacity: 0;
    -webkit-box-shadow: 0 0 0 rgba(241, 241, 241, 0);
            box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    -webkit-transform: scale(1) translateY(0px);
            transform: scale(1) translateY(0px);
    opacity: 1;
    -webkit-box-shadow: 0 0 500px rgba(241, 241, 241, 0);
            box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}

@keyframes modal {
  0% {
    -webkit-transform: scale(1) translateY(0px);
            transform: scale(1) translateY(0px);
    opacity: 0;
    -webkit-box-shadow: 0 0 0 rgba(241, 241, 241, 0);
            box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    -webkit-transform: scale(0.8) translateY(10px);
            transform: scale(0.8) translateY(10px);
    opacity: 0;
    -webkit-box-shadow: 0 0 0 rgba(241, 241, 241, 0);
            box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    -webkit-transform: scale(1) translateY(0px);
            transform: scale(1) translateY(0px);
    opacity: 1;
    -webkit-box-shadow: 0 0 500px rgba(241, 241, 241, 0);
            box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}